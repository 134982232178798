import { Component, OnInit } from "@angular/core";
import { ABOUTCARDS } from "../feed/about.card.content";
@Component({
  selector: "app-section-about",
  templateUrl: "./section-about.component.html",
  styleUrls: ["./section-about.component.scss"]
})
export class SectionAboutComponent implements OnInit {
  cards = ABOUTCARDS;
  constructor() {}

  ngOnInit() {}
}
