import { serviceModel } from "../section-services/service-card/service.model";
export const SERVICES: serviceModel[] = [
  {
    title: "Sprzątanie kompleksowe",
    content:
      "Zajmujemy się kompleksowym, cyklicznym sprzątaniem lokali użytkowych. W skład naszej oferty wchodzą między innymi: sprzątanie mieszkań, sprzątanie biur, sprzątanie restauracji, sprzątanie obiektów medycznych.",
    icon: "fas fa-broom"
  },
  {
    title: "Doczyszczanie pobudowlane",
    content:
      "Posiadamy doświadczenie z zakresu sprzątania i doczyszczania obiektów pobudowlanych i poremontowych. Zajmujemy się sprzątaniem mieszkań developerskich oraz kompleksowym doczyszczaniem obiektów po remontach.",
    icon: "fas fa-cogs"
  },
  {
    title: "Doczyszczanie maszynowe",
    content:
      "W skład naszej oferty wchodzi doczyszczanie maszynowe posadzek płaskich. Wykonujemy również zdzieranie polimerów oraz polimeryzację i konserwację posadzek.",
    icon: "fas fa-house-damage"
  }
];
