import { Component, OnInit } from "@angular/core";
import { SERVICES } from "../feed/service.card.content";

@Component({
  selector: "app-section-services",
  templateUrl: "./section-services.component.html",
  styleUrls: ["./section-services.component.scss"]
})
export class SectionServicesComponent implements OnInit {
  services = SERVICES;

  constructor() {}

  ngOnInit() {}
}
