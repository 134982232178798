import { aboutCardModel } from "../section-about/about-card/about.card.model";

export const ABOUTCARDS: aboutCardModel[] = [
  {
    title: "Dokładność",
    description:
      "Niezależnie od rodzaju sprzątanego obiektu zawsze dbamy o to, aby utrzymać go w idealnej czystości. Doskonale rozumiemy potrzeby naszych klientów i wiemy, jak czyste wnętrze wpływa na jego codzienne użytkowanie.",
    icon: "https://image.flaticon.com/icons/png/512/23/23765.png"
  },
  {
    title: "Jakość",
    description:
      "Wykonując nasze obowiązki zawsze korzystamy z najlepszej jakości produktów sprzętu sprzątającego. Dodatkowo używamy tylko profesjonalnych środków chemicznych, dzięki czemu o wiele łatwiej jest utrzymać porządek i doczyścić trudne nawierzchnie.",
    icon: "https://image.flaticon.com/icons/png/512/23/23765.png"
  },
  {
    title: "Sumienność",
    description:
      "Dla nas sprzątanie to nie tylko obowiązek. Dobrze wiemy, jak porządek wpływa na wykonywanie codziennych obowiązków. Dlatego też staramy się sprzątać tak, jakbyśmy dbali o nasze własne obiekty.",
    icon: "https://image.flaticon.com/icons/png/512/23/23765.png"
  }
];
