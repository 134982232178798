import { Component, OnInit, Input } from "@angular/core";
import { partnerModel } from "./partner.model";

@Component({
  selector: "app-partner",
  templateUrl: "./partner.component.html",
  styleUrls: ["./partner.component.scss"]
})
export class PartnerComponent implements OnInit {
  @Input() partner: partnerModel;
  constructor() {}

  ngOnInit() {}
}
