import { Component, OnInit, HostListener } from "@angular/core";
import { ConnectionService } from "../connection-service.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "app-section-contact",
  templateUrl: "./section-contact.component.html",
  styleUrls: ["./section-contact.component.scss"]
})
export class SectionContactComponent implements OnInit {
  contactForm: FormGroup;
  isSubmitting: boolean = false;
  formMessage: string;
  disableSend: boolean = true;

  formResult: String;
  formError: boolean = false;

  @HostListener("input") oninput() {
    if (this.contactForm.valid) {
      this.disableSend = false;
    }
  }

  constructor(
    private connectionService: ConnectionService,
    private formBuilder: FormBuilder
  ) {
    this.contactForm = formBuilder.group({
      contactFormName: ["", Validators.required],
      contactFormEmail: [
        "",
        Validators.compose([Validators.required, Validators.email])
      ],
      contactFormPhone: ["", Validators.required],
      contactFormSubject: ["", Validators.required],
      contactFormText: ["", Validators.required]
    });
  }

  ngOnInit() {}

  onSubmit(event) {
    event.preventDefault();
    this.isSubmitting = true;
    this.connectionService.sendMessage(this.contactForm.value).subscribe(
      () => {
        this.formError = false;
        this.formResult = "Twoja wiadomość została pomyślnie wysłana, skontaktujemy się z Tobą najszybciej jak to możliwe. Dziękujęmy!"
        this.contactForm.reset();
      },
      error => {
        this.formError = true;
        this.formResult = "Nie udało się wysłać wiadomości. Upewnij się, że wszystkie pola są poprawnie uzupełnione i spróbuj ponownie."
        console.log("Error: ", error);
      }
    );
    setTimeout(() => {
      this.isSubmitting = false;
    }, 2000);
  }
}
