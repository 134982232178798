import { partnerModel } from "../section-partners/partner/partner.model";

export const PARTNERS: partnerModel[] = [
  {
    name: "GTE",
    img: "assets/images/GTEOZE_Logo_Color.svg",
    alt: "gteoze",
    customize: ""
  },
  {
    name: "Chopper",
    img: "assets/images/chopper.png",
    alt: "chopper",
    customize: "customChop"
  },
  {
    name: "Alzheimer",
    img: "assets/images/wca-logo-biale.png",
    alt: "azlheimer wroclaw",
    customize: "customAlz"
  },
  {
    name: "TGS",
    img: "assets/images/TGS_BRZOZA_LogoSVG.svg",
    alt: "tgs brzoza",
    customize: ""
  },
  {
    name: "Thermicare",
    img: "assets/images/thermi.png",
    alt: "thermicare",
    customize: ""
  },
  {
    name: "JS",
    img: "assets/images/js.jpg",
    alt: "js-metals",
    customize: ""
  }
];
