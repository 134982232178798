import { Component, OnInit, Input } from "@angular/core";
import { aboutCardModel } from "./about.card.model";

@Component({
  selector: "app-about-card",
  templateUrl: "./about-card.component.html",
  styleUrls: ["./about-card.component.scss"]
})
export class AboutCardComponent implements OnInit {
  @Input() card: aboutCardModel;
  constructor() {}

  ngOnInit() {}
}
