import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SectionHeaderComponent } from "./section-header/section-header.component";
import { SectionAboutComponent } from "./section-about/section-about.component";
import { SectionServicesComponent } from "./section-services/section-services.component";
import { SectionPartnersComponent } from "./section-partners/section-partners.component";
import { SectionNewsComponent } from "./section-news/section-news.component";
import { SectionFooterComponent } from "./section-footer/section-footer.component";
import { MenuComponent } from "./section-header/menu/menu.component";
import { AboutCardComponent } from "./section-about/about-card/about-card.component";
import { CarouselComponent } from "./carousel/carousel.component";
import { SlideComponent } from "./carousel/slide/slide.component";
import { ServiceCardComponent } from "./section-services/service-card/service-card.component";
import { PartnerComponent } from "./section-partners/partner/partner.component";
import { SectionContactComponent } from "./section-contact/section-contact.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    SectionHeaderComponent,
    SectionAboutComponent,
    SectionServicesComponent,
    SectionPartnersComponent,
    SectionNewsComponent,
    SectionFooterComponent,
    MenuComponent,
    AboutCardComponent,
    CarouselComponent,
    SlideComponent,
    ServiceCardComponent,
    PartnerComponent,
    SectionContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
