import { Component, OnInit, HostListener, Inject } from "@angular/core";
import {
  trigger,
  state,
  transition,
  style,
  animate
} from "@angular/animations";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-section-header",
  templateUrl: "./section-header.component.html",
  styleUrls: ["./section-header.component.scss"],
  animations: [
    trigger("fade", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate(300)]),
      transition(":leave", [animate(500)])
    ])
  ]
})
export class SectionHeaderComponent implements OnInit {
  constructor(@Inject(DOCUMENT) document) {}

  ngOnInit() {}

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    if (window.pageYOffset > 20) {
      let element = document.getElementById("navbar");
      let element2 = document.getElementById("introText");
      element.classList.add("visible");
      element2.classList.add("visible");
    } else {
      let element = document.getElementById("navbar");
      let element2 = document.getElementById("introText");
      element.classList.remove("visible");
      element2.classList.remove("visible");
    }
  }
}
