import { slideModel } from "../carousel/slide/slide.model";

export const SLIDES: slideModel[] = [
  {
    title: "Dokładność",
    subtitle: "perfekcyjne sprzątanie",
    content:
      "Niezależnie od rodzaju sprzątanego obiektu zawsze dbamy o to, aby utrzymać go w idealnej czystości. Doskonale rozumiemy potrzeby naszych klientów i wiemy, jak czyste wnętrze wpływa na jego codzienne użytkowanie."
  },
  {
    title: "Jakość",
    subtitle: "doczyszczanie na błysk",
    content:
      "Wykonując nasze obowiązki zawsze korzystamy z najlepszej jakości produktów sprzętu sprzątającego. Dodatkowo używamy tylko profesjonalnych środków chemicznych, dzięki czemu o wiele łatwiej jest utrzymać porządek i doczyścić trudne nawierzchnie."
  },
  {
    title: "Sumienność",
    subtitle: "porządek jest najważniejszy",
    content:
      "Dla nas sprzątanie to nie tylko obowiązek. Dobrze wiemy, jak porządek wpływa na wykonywanie codziennych obowiązków. Dlatego też staramy się sprzątać tak, jakbyśmy dbali o nasze własne obiekty."
  }
];
