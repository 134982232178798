import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-news',
  templateUrl: './section-news.component.html',
  styleUrls: ['./section-news.component.scss']
})
export class SectionNewsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
