import { Component, OnInit } from "@angular/core";
import { PARTNERS } from "../feed/partner.content";

@Component({
  selector: "app-section-partners",
  templateUrl: "./section-partners.component.html",
  styleUrls: ["./section-partners.component.scss"]
})
export class SectionPartnersComponent implements OnInit {
  partners = PARTNERS;
  constructor() {}

  ngOnInit() {}
}
