import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { SLIDES } from "../feed/slide.content";
import { slideModel } from "./slide/slide.model";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"]
})
export class CarouselComponent implements OnInit {
  selectedSlide: slideModel;
  slides = SLIDES;
  interval;
  index: number = 0;
  constructor() {}

  ngOnInit() {
    this.selectedSlide = this.slides[this.index];
    this.startInterval();
  }

  onSlideSelected(slide: slideModel) {
    this.selectedSlide = slide;
    this.index = this.slides.indexOf(slide);
    clearInterval(this.interval);
    this.startInterval();
  }

  startInterval() {
    this.interval = setInterval(() => {
      this.selectedSlide = this.slides[this.index];
      this.index++;
      if (this.index > 2) {
        this.index = 0;
      }
    }, 5000);
  }
}
