import { Component, OnInit, Input } from "@angular/core";
import { serviceModel } from "./service.model";

@Component({
  selector: "app-service-card",
  templateUrl: "./service-card.component.html",
  styleUrls: ["./service-card.component.scss"]
})
export class ServiceCardComponent implements OnInit {
  @Input() service: serviceModel;
  constructor() {}

  ngOnInit() {}
}
